import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import manuel from "../images/MANUEL.jpg"

const MensajePage = () => (
    <Layout>
        <SEO title="Home"/>
        {/* ============================================================== */}
        {/* Feature 3  */}
        {/* ============================================================== */}
        <div className="up bg-white spacer feature3 mt-5">
            <div className="container">
                {/* Row  */}
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="title">Mensaje del Presidente</h3>
                        <h6 className="subtitle">Queridos amigos,</h6>
                    </div>
                </div>
                {/* Row  */}
                <div className="row">
                    {/* Column */}
                    <div className="col-md-8">
                    <p className="m-t-20">Como profesionista y empresario, me brinda gran satisfacción el ser parte de la
                        creación de la Fundación Pedro Pérez Garrido, en honor a mi difunto abuelo, uno de los más reconocidos
                        luchadores sociales de Quintana Roo. La Fundación nace con el
                        propósito de promover un desarrollo sostenible para el sur del estado.</p>
                        <p className="m-t-20">Nos centramos en áreas estratégicas, como los derechos de la propiedad de la tierra
                        y creación de cadenas de valor, que consideramos esenciales para la prosperidad de Othon P. Blanco y el sur
                        del estado en general. Para este propósito contamos con el respaldo y la experiencia de jóvenes talentos, con
                        la firme convicción de ver renacer a Chetumal y recuperar la dignidad del sur.</p>
                    </div>
                        <div className="col-md-4">
                        <img src="../images/MANUEL.JPG" alt="wrapkit"
                             className="img-fluid"/>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <p className="m-t-20">Consideramos trascendental la búsqueda de nuevo conocimiento para que el
                        progreso social y económico llegué de forma justa y equitativa. Al mismo tiempo, creemos que
                        es indispensable actuar con responsabilidad y sensibilidad ante la desigualdad social y económica, que
                        afecta a los grupos más vulnerables, especialmente en vista del actual contexto marcado por la pandemia de COVID-19.</p>
                        <p className="m-t-20">Y, como lo fue en los 30s-50s la devastación forestal del estado a manos de intereses
                        rapaces -recordemos que, de hecho, Chetumal significa lugar donde “brillan las maderas rojas”, pero ahora no
                        tenemos ni maderas- ahora, existe una lucha por preservar el inventario territorial del estado, en cuyo rescate
                        podremos encontrar la gasolina necesaria para el desarrollo: esforcémonos juntos para preservar el patrimonio del
                        gran pueblo de Quintana Roo, y salgamos adelante.</p>
                        <p className="m-t-20">Nunca dejaremos de ejercer acción social y donativos a los que más lo necesitan, mas ese
                        apoyo no es suficiente, el apoyo a adultos mayores del INSABI no es suficiente, el ahora cuasi extinto “jóvenes
                        construyendo el futuro” no es suficiente, debemos reconstruir la economía del sur del estado, para tener bienestar
                        sostenible, que evite la constante migración al norte del estado.</p>
                        <p className="m-t-20">Consideramos que la simulación y la incapacidad al ocupar un puesto de responsabilidad
                        es una forma de corrupción… que alguien, quien quiera que sea, ocupe un puesto de responsabilidad, sin los conocimientos básicos y
                        la capacidad ejecutiva mínima, nos perjudica a todos; basta ver el bacheo de las calles de Chetumal, la falta de
                        alumbrado y de recolección de basura, problemas que desde mis recuerdos más profundos llevan 35 años.
                        Hagamos conciencia y pongamos cartas en el asunto.</p>
                        <p className="m-t-20">Es el momento de tomar en nuestras manos el futuro que siempre hemos soñado para nuestra
                        amada tierra: superemos el huracán del olvido.</p>
                            </div>
                   
                       
                       
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                        <video controls>
                            <source src='../videos/Manolo11.mp4' type="video/mp4" />
                        </video>
                        </div>
                    </div>
                    
                
                
            </div>
        </div>

        <div className="spacer feature46 bg-white" style={{backgroundImage: 'url(images/features/img1.jpg)'}}>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="card card-shadow " data-aos="fade-right" data-aos-duration={1200}>
                            <div className="card-body p-30 text-center">
                                <h6 className="font-medium mb-0">Saludos cordiales, <br/>Manuel S. Pérez Alavez</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* ============================================================== */}
        {/* End Call-to-Action 2  */}
        {/* ============================================================== */}

    </Layout>
)

export default MensajePage
